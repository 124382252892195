/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import CategoryPage from '@scandipwa/scandipwa/src/route/CategoryPage/CategoryPage.component';
import { updateMeta } from '@scandipwa/scandipwa/src/store/Meta/Meta.action';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import { openCategoryFilters } from 'Store/CategoryFilters/CategoryFilters.action';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { resetSubCategory, selectSubCategory } from 'Store/SubCategoryFilterTags/SubCategoryFilterTags.action';
import {
    resetTotalItemsSubCategoryFiltered,
    setTotalItemsSubCategoryFiltered
} from 'Store/TotalItemsSubCategoryFiltered/TotalItemsSubCategoryFiltered.action';
import { toggleScroll } from 'Util/Browser';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase/BrowserDatabase';
import { CATEGORY_FILTER_OVERLAY_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import { LAYOUT_KEY, ORDER_BY_OVERLAY } from './CategoryPage.config';
import {
    appendWithStoreCode,
    getQueryParam,
    setQueryParams,
} from 'Util/Url';
import history from 'Util/History';
export const MetaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */ 'Store/Meta/Meta.dispatcher'
);

/** @namespace Pwa/Route/CategoryPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    activeOverlay: state.OverlayReducer.activeOverlay,
    isCategoryFiltersState: state.CategoryFiltersReducer.isShowCategoryFilters,
    subCategorySelected: state.SubCategoryFilterTagsReducer.subCategorySelected,
    totalItemsSubCategoryFiltered: state.TotalItemsSubCategoryFilteredReducer.totalItemsSubCategoryFiltered
});

/** @namespace Pwa/Route/CategoryPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    // TODO extend mapDispatchToProps
    ...sourceMapDispatchToProps(dispatch),
    showOverlay: () => dispatch(toggleOverlayByKey(ORDER_BY_OVERLAY)),
    hideOverlay: () => dispatch(hideActiveOverlay()),
    openCategoryFilters: () => dispatch(openCategoryFilters()),
    filterTagSelectSubCategory: (categoryName) => dispatch(selectSubCategory(categoryName)),
    resetSubCategoryTag: (categoryName) => dispatch(resetSubCategory(categoryName)),
    setTotalItemsFiltered: (total) => dispatch(setTotalItemsSubCategoryFiltered(total)),
    resetTotalItemsFiltered: (total) => dispatch(resetTotalItemsSubCategoryFiltered(total)),
    updateMeta: (meta) => MetaDispatcher.then(() => dispatch(updateMeta(meta)))
});

/** @namespace Pwa/Route/CategoryPage/Container/CategoryPageContainer */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    static propTypes = {
        ...SourceCategoryPageContainer.propTypes,
        showOverlay: PropTypes.func.isRequired,
        hideOverlay: PropTypes.func.isRequired,
        openCategoryFilters: PropTypes.func.isRequired,
        clearCategory: PropTypes.func.isRequired,
        subCategorySelected: PropTypes.string,
        filterTagSelectSubCategory: PropTypes.func.isRequired,
        resetTagClearSubCategory: PropTypes.func.isRequired,
        totalItemsSubCategoryFiltered: PropTypes.number,
        resetTotalItemsFiltered: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...SourceCategoryPageContainer.defaultProps,
        subCategorySelected: '',
        totalItemsSubCategoryFiltered: 0
    };

    config = {
        sortKey: 'position',
        sortDirection: 'ASC'
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            ...this.state,
            onSelectedTagPageSize: 200,
            isCategoryFiltered: 0
        };
    }

    onLayoutSwitcherClick(layout) {
        BrowserDatabase.setItem(layout, LAYOUT_KEY);
        this.setState({ selectedLayoutType: layout });
        // EVENTO GA4
        window?.dataLayer?.push({
            event: 'click_view_mode',
            category: 'click',
            action: 'view_mode',
            label: layout
        });
    }

    onOrderByOpen() {
        const { showOverlay } = this.props;
        showOverlay();
    }

    onOrderByOutsideClick() {
        const { activeOverlay, hideOverlay } = this.props;
        if (activeOverlay !== ORDER_BY_OVERLAY) {
            return;
        }
        hideOverlay();
    }

    openCategoryFilter() {
        const { openCategoryFilters, isMobile } = this.props;
        const scrollAmount = window.pageYOffset || document.body.scrollTop;

        openCategoryFilters();
        toggleScroll(false);
        if (!isMobile) {
            document.body.style.marginTop = `${-scrollAmount}px`;
        }
    }

    filtersEnabled() {
        const {
            category
        } = this.props;

        return category.mc_filters_enabled;
    }


    containerFunctions = {
        ...this.containerFunctions,
        onGridButtonClick: this.onGridButtonClick.bind(this),
        onListButtonClick: this.onListButtonClick.bind(this),
        openCategoryFilter: this.openCategoryFilter.bind(this),
        onLayoutSwitcherClick: this.onLayoutSwitcherClick.bind(this),
        onOrderByOpen: this.onOrderByOpen.bind(this),
        onOrderByOutsideClick: this.onOrderByOutsideClick.bind(this),
        onSortChange: this.onSortChange.bind(this),
        filtersEnabled: this.filtersEnabled.bind(this)
    };

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        localStorage.removeItem('subCategorySelected');
    }

    componentDidMount() {
        super.componentDidMount();
        const { resetTotalItemsFiltered, updateMeta } = this.props;
        if (window.location.pathname.indexOf('angolo-delle-occasioni') > -1) {
            updateMeta({
                robots: 'noindex, nofollow'
            });
        }
        localStorage.removeItem('displayed_product_card_count');
        localStorage.removeItem('total_product_card_count');
        localStorage.removeItem('total_not_filtered_product_card_count');

       resetTotalItemsFiltered();
    }

    localSelectSubCategory(categoryUrl) {
        const {
            filterTagSelectSubCategory
        } = this.props;

        filterTagSelectSubCategory(categoryUrl);
    }

    onSortChange(sortDirection, sortKey) {
        const { location } = history;
        const sortKeyString = Array.isArray(sortKey) ? sortKey.join(',') : sortKey;
        
        setQueryParams({ sortKey: sortKeyString, sortDirection, page: '' }, location, history);
        updateMeta();
    }

    updateMeta(){
        const {
            updateMetaFromCategory,
            category,
            category: {
                canonical_url,
            } = {},
        } = this.props;

        const meta_robots = history?.location?.search
            ? ''
            : 'follow, index';

        updateMetaFromCategory({
            ...category,
            meta_robots,
            canonical_url: this.getCanonicalWithPageNumber(canonical_url),
        });
    }

    getCanonicalWithPageNumber(canonical_url) {
        if (!canonical_url) {
            return null;
        }

        const pageNumber = getQueryParam('page', history?.location);

        if (pageNumber) {
            return canonical_url.concat(`?page=${pageNumber}`);
        }

        return canonical_url;
    }


    getSelectedFiltersFromUrl() {
        const { location } = history;
        const selectedFiltersString = (getQueryParam('customFilters', location, false) || '').split(';');
        console.log(selectedFiltersString, "selectedFiltersString");
        return selectedFiltersString.reduce((acc, filter) => {
            if (!filter) {
                return acc;
            }
            const [key, value] = filter.split(':');

            return { ...acc, [ key ]: value.split(',') };
        }, {});
    }

    render() {
        const { pageSize } = this.config;
        const {
            defaultPlpType,
            selectedLayoutType,
            activeLayoutType,
            onSelectedTagPageSize,
            isCategoryFiltered
        } = this.state;

        const { resetSubCategoryTag } = this.props;

        /* retrieving selected redux status from local storage */
        const subCategorySelected = localStorage.getItem('subCategorySelected');

        // eslint-disable-next-line fp/no-let
        let actualPageSize;
        /* if tag is selected, reload category page with 200 items and thus no pagination */
        if (!(subCategorySelected === null || subCategorySelected === '/letti.html')) {
            actualPageSize = onSelectedTagPageSize;
            this.setState({ isCategoryFiltered: (isCategoryFiltered + 1) });
        } else {
            actualPageSize = pageSize;
        }

        /* if isCategoryFiltered has remained unchanged, we no filter has to be displayed as active but TUTTO. without reset redux state remains active when loading new beds categories */
        if (isCategoryFiltered === 0) {
            resetSubCategoryTag();
        }

        /* setting subCategorySelected redux status */
        if (!(subCategorySelected === null || subCategorySelected === undefined || subCategorySelected === '/letti.html')) {
            this.localSelectSubCategory(subCategorySelected);
        }
        return (
            <CategoryPage
                {...this.props}
                pageSize={actualPageSize}
                defaultPlpType={defaultPlpType}
                selectedLayoutType={selectedLayoutType}
                activeLayoutType={activeLayoutType}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer);
