import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    SearchPageContainer as SourceSearchPageContainer
} from 'SourceRoute/SearchPage/SearchPage.container';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

import SearchPage from './SearchPage.component';
/** @namespace Pwa/Route/SearchPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Pwa/Route/SearchPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    toggleBreadcrumbs: (isVisible) => dispatch(toggleBreadcrumbs(isVisible))

});

/** @namespace Pwa/Route/SearchPage/Container/SearchPageContainer */
export class SearchPageContainer extends SourceSearchPageContainer {
    static propTypes = {
        toggleBreadcrumbs: PropTypes.func.isRequired
    };

    config = {
        sortKey: 'relevance',
        sortDirection: 'DESC'
    };

    componentDidMount() {
        const { toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(false);
    }

    render() {
        return (
            <SearchPage
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
                // addded here to not override the container props
              search={ this.getSearchParam() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPageContainer);
